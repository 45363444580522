
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { setSinoepcAuth, getSinoepcAuth, setSinoepcInfo } from '@/utils/cookies'

@Component({
  name: 'Sinopec',
  mixins: [GlobalMixin]
})
export default class SinopecPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() public storeId: string = ''
  @Provide() public uid: string = ''
  @Provide() public uname: string = ''
  @Provide() public tel: string = ''
  @Provide() public sinopecSign: string = ''
  @Provide() public timestamp: string = ''
  async created () {
    this.storeId = getCurrentQuery('storeId')
    this.uid = getCurrentQuery('uid')
    this.tel = getCurrentQuery('tel')
    this.uname = decodeURI(getCurrentQuery('uname'))
    if (this.uname && this.uname.length > 0) {
      this.uname = this.uname.trim()
      if (this.uname.length === 0) {
        this.uname = this.uid
      }
    }
    this.sinopecSign = getCurrentQuery('sign')
    this.timestamp = getCurrentQuery('timestamp')
    if (getSinoepcAuth()) {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setSinoepcAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const data: any = {
      storeId: this.storeId,
      uid: this.uid,
      tel: this.tel,
      uname: this.uname,
      sinopecSign: this.sinopecSign,
      timestamp: this.timestamp
    }
    setSinoepcInfo(JSON.stringify(data))
    data.grant_type = 'Auth_Sinopec'
    try {
      const res = await httpHelper.post({
        url: 'authorize/sinopec',
        contentType: 'application/x-www-form-urlencoded',
        data: data,
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      location.replace(res.value.extend.gotoUrl)
    } catch (err) {
      sessionStorage.setItem('goBackUrl', location.href)
      this.goTo(err, 9, 21)
      this.$toasted.show(err.msg || err.message)
    }
  }
}
