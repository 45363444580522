
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { getPlatformAuth, setPlatformAuth } from '@/utils/cookies'

@Component({
  name: 'PlatformAuth',
  mixins: [GlobalMixin]
})
export default class PlatformAuthPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() public platform: string = ''
  @Provide() public urlTag: string = ''
  @Provide() public kvPairs: { [k: string]: string } = {}
  async created () {
    this.kvPairs = (getCurrentQuery(undefined) || {}) as { [k: string]: string }
    this.platform = this.$route.params.platform
    this.urlTag = this.$route.params.urlTag || ''
    console.log(this.urlTag)
    console.log(getPlatformAuth(this.platform))
    if (getPlatformAuth(this.platform)) {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const data: any = {
      ...this.kvPairs,
      urlTag: this.urlTag
    }
    data.grant_type = 'Auth_' + this.platform
    try {
      const res = await httpHelper.post({
        url: 'authorize/' + this.platform,
        contentType: 'application/x-www-form-urlencoded',
        data: data,
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      setPlatformAuth(this.platform)
      location.replace(res.value.extend.gotoUrl)
    } catch (err) {
      sessionStorage.setItem('goBackUrl', location.href)
      this.goTo(err, 0, 0)
      this.$toasted.show(err.msg || err.message)
    }
  }
}
